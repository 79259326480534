import * as XLSX from 'xlsx';

export default function exportToExcel(data) {
  const workbook = XLSX.utils.book_new();

  data.forEach((subArray, index) => {
    const worksheet = XLSX.utils.json_to_sheet(subArray);
    const sheetName = `Sheet${index + 1}`;
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  });

  // Generate XLSX file as binary
  const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

  // Create a Blob and trigger download
  const blob = new Blob([wbout], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'output.xlsx';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}