import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button, Header } from 'semantic-ui-react';

import tripPropType from '../tripPropType';

import TripSummary from '../TripSummary';
import EmptyState from '../../common/EmptyState';
import withi18n from 'weego-common/src/hoc/i18n';
import { toArray } from 'lodash';

const UpcomingTripsSummary = memo(function UpcomingTripsSummary({
  trips,
  search,
  loading,
  onTripDetailsClick,
  onTripNotificationsClick,
  focusTrips,
  focusedTrips,
  targetVehicle,
  t,
}) {
  const focusedTripsArray = toArray(focusedTrips);
  return (
    <div style={styles.container}>
      {search && (
        <Header sub color="grey" style={styles.searchResultsHeader}>
          {t('{{count}} résultats trouvés', {
            count: trips.length,
          })}
        </Header>
      )}
      <Button onClick={() => focusTrips(focusedTripsArray.length > 0 ? [] : trips)}>Sélectionner tout</Button>
      <div style={styles.listContainer}>
        {loading || trips?.length ? (
          trips?.map(trip => {
            const isFocused = !!focusedTripsArray.find(t => t.id === trip.id);
            return (
            <TripSummary
              onClick={(trip) => focusTrips(isFocused ? [...focusedTripsArray.filter(t => t.id !== trip.id)] : [...focusedTripsArray, trip])}
              onTargetClick={targetVehicle}
              onDetailsClick={onTripDetailsClick}
              onNotificationsClick={onTripNotificationsClick}
              key={`${trip.id}-${trip.departureTime}`}
              trip={trip}
              focused={!!(focusedTrips && focusedTrips[trip.id])}
              legs={focusedTrips && focusedTrips[trip.id]?.path?.legs}
            />
          )})
        ) : (
          <EmptyState text={t('Aucun trajet trouvé')} />
        )}
      </div>
    </div>
  );
});

const styles = {
  container: {
    height: '100%',
    overflow: 'visible',
  },
  listContainer: {
    position: 'relative',
    height: '100%',
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  searchResultsHeader: {
    marginTop: -18,
    // 290px = width of a trip summary card, this effectively
    // computes horizontal padding
    marginLeft: 'calc((100% - 290px) / 2)',
  },
};

UpcomingTripsSummary.propTypes = {
  trip: PropTypes.arrayOf(tripPropType),
  search: PropTypes.string,
  searching: PropTypes.bool,
  onTripDetailsClick: PropTypes.func.isRequired,
  onTripNotificationsClick: PropTypes.func.isRequired,
  focusTrip: PropTypes.func.isRequired,
  clearTrips: PropTypes.func.isRequired,
  requestTripsInPeriod: PropTypes.func.isRequired,
};

export default withi18n('dashboard')(UpcomingTripsSummary);
